import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../views/AdminView.vue'
import SingleView from '../views/SingleView.vue'
import EditView from '../views/EditView.vue'
import { store } from '@/common/store'
import service from '@/common/service'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/view/:id',
    name: 'view',
    component: SingleView
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: EditView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.pages.length) 
    await service.loadPages()
  if (localStorage['user'] && !store.user.token)
    store.user = JSON.parse(localStorage['user'])
  if (store.user.token) {
    next()
  } else {
    if (to.path ==='/admin')
      next({
        path: '/login',
      })
    else next()
  }
})

export default router
