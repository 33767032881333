import axios from "axios"
import config from '@/config'
import {store, User} from '@/common/store'

 

class Service {

  dataURItoBlob(dataURI: any) {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  async savePhoto(obj: any): Promise<string> {
    // TODO
    // На входе: {id: 0, photo: "data:image/octet-stream;base64,..."}
    // На выходе: url файла, который был успешно сохранен на сервере
    // return obj.id.toString()  // fake data
    try {
      const formData = new FormData();
      formData.append('upload', this.dataURItoBlob(obj.photo), 'image.jpg');
      const res = await axios({
        method: 'post',
        url: config.server + 'upload/images',
        data: formData,
        headers: {
          'Authorization': `Bearer ${(store.user as User).token}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data'
        }
      })
      return res.data.data.link
    } catch (error: any) {
      return error.response
    }
  }
   
  async login(login: string, password: string) {
    store.isLoading = true
    try {
      const res = await axios({
        method: 'post',
        url: config.server + 'login',
        data: {
          login,
          password
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
      })
      store.user = res.data
      localStorage['user'] = JSON.stringify(store.user)
      return null
    } catch (error: any) {
      //console.log(error.response)
      return error.response
    }
  }

  async updateUser() {
    if (store.user) {
      try {
        const res = await axios({
          method: 'put',
          url: config.server + 'users/'+store.user._id,
          headers: {
            'Authorization': `Bearer ${store.user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
          data: store.user
        })
        store.user = res.data.data
        localStorage['user'] = JSON.stringify(store.user)
        return null
      } catch (error: any) {
        return error.response.data.message
      }
    }
  }

  logout() {
    localStorage['user'] = ''
    store.user = new User()
  }

  async loadPages() {
    store.isLoading = true
    try {
      const res = await axios({
        method: 'get',
        url: config.server + 'pages',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
      })
      store.pages = res.data.data
      // store.pages = [
      //   {
      //     _id: '55',
      //     name: 'Первая запись',
      //     content: {"time":1558356864490,"blocks":[{"type":"paragraph","data":{"text":"First text"}},{"type":"paragraph","data":{"text":"Second text"}}],"version":"2.13"}
      //   }
      // ]
      return null
    } catch (error: any) {
      console.log(error.response)
      return error.response
    }
  }

  async savePage(obj: any) {
    store.isLoading = true
    const idx = store.pages.indexOf(obj)
    if (!obj._id) delete obj._id
    console.log('obj', obj.content)
    try {
      const res = await axios({
        method: obj._id ? 'put' : 'post',
        data: obj,
        url: config.server + (obj._id ? 'pages/' + obj._id : 'pages'),
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${(store.user as User).token}`,
        },
      })
      console.log(res.data.data)

      store.pages[idx] = res.data.data
      return null
    } catch (error: any) {
      console.log(error.response)
      return error.response
    }
  }

  async deletePage(obj: any) {
    store.isLoading = true
    if (!obj._id) return
    console.log('obj', obj.content)
    try {
      const res = await axios({
        method: 'delete',
        url: config.server + 'pages/' + obj._id,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${(store.user as User).token}`,
        },
      })
      console.log(res.data.data)

      return null
    } catch (error: any) {
      console.log(error.response)
      return error.response
    }
  }

}



const service = new Service()

export default service
