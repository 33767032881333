import { reactive, computed } from "vue"

export class User {
  _id = ''
  name = ''
  email = ''
  phone = ''
  password = ''
  token = ''
}

export class Photo {
  photo?: string
}

export class Page {
  _id?: string = ''
  name?: string = ''
  topic?: string = ''
  draft?: boolean = true
  date?: Date = new Date()
  content?: any = {}
}

export const store = reactive({
  isLoading: true,
  user: new User(),
  pages: [] as Page[]
})
