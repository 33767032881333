const config =
    {
        server: '/api/',
        //wsserver: 'wss://rilio.net:5070',
    }
    if (location.hostname == 'localhost') config.server = 'http://localhost:5000/'
        // localhost:8080 всегда будет работать с локальным сервером
        // но с удаленным сервером можно работать на 127.0.0.1:8080

export default config
