import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import router from './router'

 

//import '@/assets/css/theme.css'
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css'; 
import 'primeflex/primeflex.css';
import './assets/css/app.scss'   
    
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Panel from "primevue/panel";
import Password from "primevue/password";
import RadioButton from "primevue/radiobutton";
import DataTable from "primevue/datatable";
import DataView from 'primevue/dataview';
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import TabMenu from "primevue/tabmenu";
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Tooltip from "primevue/tooltip";
import Toast from "primevue/toast";
import ToastService from 'primevue/toastservice';
import Image from 'primevue/image';
//import Sidebar from "primevue/sidebar/sidebar";
//import Galleria from "primevue/galleria/galleria";
//import Accordion from "primevue/accordion/accordion";
//import AccordionTab from "primevue/accordiontab/accordiontab";

const app = createApp(App);
app.use(PrimeVue);
app.use(ToastService);

app.component('Dialog', Dialog);
//app.component('Sidebar', Sidebar);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Button', Button);
app.component('SplitButton', SplitButton);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('AutoComplete', AutoComplete);
app.component('TabMenu', TabMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Toast', Toast);
app.component('Image', Image);
//app.component('Galleria', Galleria);
//app.component('Accordion', Accordion);
//app.component('AccordionTab', AccordionTab);
app.directive('tooltip', Tooltip);




app.use(router).mount('#app')
